<template>
  <v-card id="nuevavideo" :style="tamAlto">
    <div v-if="!showSpecialtiesMsg">
      <v-toolbar v-if="es_fullscreen" flat>
        <v-btn icon @click.native="cancelarNuevaVideo">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>

        <v-spacer />

        <v-btn :disabled="disabledButton" color="primary" dark @click="enviarVideo">
          {{ $t('videoconsulta.crear_video') }}
        </v-btn>
      </v-toolbar>

      <v-toolbar v-else flat>
        <v-toolbar-title>
          {{
            nuevavideo.destinatario.length > 1
              ? $t('videoconsulta.crear_muticonferencia')
              : $t('videoconsulta.crear_para_paciente', {
                tipoVideo: tipoVideoTrans,
              })
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn data-test="patient-edit-button-close" icon @click="cancelarNuevaVideo">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-2 py-0">
        <v-layout wrap>
          <v-flex v-if="es_fullscreen" xs12 class="text-center">
            <h4 class="titulo_nueva_video">
              {{
                nuevavideo.destinatario.length > 1
                  ? $t('videoconsulta.crear_muticonferencia')
                  : $t('videoconsulta.crear_para_paciente', {
                    tipoVideo: tipoVideoTrans,
                  })
              }}
            </h4>
          </v-flex>

          <template v-if="showMinimumServices">
            <v-flex xs12>
              <v-alert text color="error">{{ $t('videoconsulta.sin_tiposvideo') }}</v-alert>
            </v-flex>
          </template>

          <template v-if="!showMinimumServices">
            <v-flex xs12>
              <v-text-field
                data-test="patient-edit-input-tag"
                v-if="haveCreateVideoWithTel && forceShowInput && pacientes.length > 0"
                v-model="patientName"
                filled
                dense
                outlined
                :autofocus="true"
                :placeholder="$t('mensajeria.para')"
                type="text"
                required
              />

              <v-chip
                v-else-if="haveCreateVideoWithTel && pacientes.length > 0 && patientName !== ''"
                color="blue lighten-5"
              >{{ patientName }}</v-chip
              >

              <v-text-field
                v-else-if="forceShowInput && haveCreateVideoWithTel && pacientes.length === 0"
                v-model="patientName"
                filled
                dense
                outlined
                :autofocus="true"
                :placeholder="$t('mensajeria.para')"
                type="text"
                required
              />

              <v-autocomplete
                v-else-if="pacientes.length !== 1"
                v-model="nuevavideo.destinatario"
                :search-input.sync="receiverInput"
                filled
                dense
                outlined
                :autofocus="true"
                :items="pacientes"
                chips
                multiple
                :placeholder="$t('mensajeria.para')"
                item-text="label"
                item-value="value"
                @change="selectPatient"
                @click="clickSelectPatient"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip color="blue lighten-5" close small @click:close="quitarDestinatario(data.item)">
                    {{ data.item.label }}
                  </v-chip>
                </template>
                <template slot="item" slot-scope="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item" />
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title @click="seleccionarDestinatario(data.item)">
                        {{ data.item.label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-chip
                v-else-if="
                  !haveCreateVideoWithTel || (haveCreateVideoWithTel && pacientes.length === 1 && !forceShowInput)
                "
                color="blue lighten-5"
                close
                class="mb-4"
                @click:close="quitarDestinatario('')"
              >
                <v-avatar color="blue lighten-4">
                  <img src="/static/paciente_icon.png" />
                </v-avatar>
                {{ pacientes[0].label }}
              </v-chip>
            </v-flex>

            <v-flex v-if="haveCreateVideoWithTel" xs12>
              <v-tel-field
                data-test="patient-edit-input-video-phone"
                v-model="patientPhone"
                dense
                filled
                :outlined="true"
                disabled-fetching-country
                :default-country="defaultCountryPhone"
                :placeholder="$t('common.phone_number')"
                :allow-web-phone="true"
                @country-changed="changeCountry"
                @call="callEvent"
              />
            </v-flex>

            <v-flex v-if="is_receptionist && profesionales.length > 0" xs12>
              <v-select
                v-if="nuevavideo.profesional === ''"
                v-model="nuevavideo.profesional"
                filled
                outlined
                hide-details
                dense
                :placeholder="$t('common.professional')"
                class="buscadorSelector mb-6"
                :items="profesionales"
                item-text="label"
                item-id="id"
                @change="cambiaProfesional"
              />
              <v-chip v-else color="grey lighten-2 mb-4">
                {{ nuevavideo.profesional.label }}
                <v-btn icon @click="quitarProfesional">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-chip>
            </v-flex>

            <v-flex v-if="tiposSelect.length > 1" v-show="showType" xs12>
              <v-select
                v-if="nuevavideo.tipo === ''"
                v-model="nuevavideo.tipo"
                filled
                dense
                outlined
                hide-details
                :placeholder="$t('videoconsulta.tipo')"
                class="buscadorSelector mb-6"
                item-text="label"
                item-id="id"
                :items="tiposSelect"
                @input="cambiaTipo"
              />
            </v-flex>
            <v-flex xs12>
              <v-chip v-if="nuevavideo.tipo" color="grey lighten-2 mb-6">
                {{ nuevavideo.tipo.label }}
                <v-btn v-if="tiposSelect.length > 1" icon @click="quitarTipo">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-chip>
            </v-flex>
            <v-flex v-if="specialties.length > 1" xs12>
              <v-autocomplete
                v-model="nuevavideo.specialtySelected"
                class="mb-6"
                hide-details
                item-text="name"
                item-value="id"
                persistent-hint
                outlined
                clearable
                filled
                dense
                :label="$t('agenda.especialidad')"
                :items="specialties"
              />
            </v-flex>
            <v-flex xs6>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="nuevavideo.fecha"
                transition="scale-transition"
                offset-y
                nudge-top="20"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    data-test="patient-edit-input-video-date"
                    filled
                    dense
                    outlined
                    :value="dateFormatted"
                    :label="$t('common.date')"
                    append-inner-icon="mdi-event"
                    readonly
                    class="mr-2"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="nuevavideo.fecha"
                  no-title
                  scrollable
                  :locale="this.$i18n.locale"
                  first-day-of-week="1"
                  :min="new Date().toISOString().substr(0, 10)"
                  @change="$refs.menu.save(nuevavideo.fecha)"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">{{ $t('common.cancel') }}</v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(nuevavideo.fecha)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                data-test="patient-edit-input-video-time"
                v-model="nuevavideo.hora"
                filled
                dense
                outlined
                type="time"
                :placeholder="$t('common.hora')"
                append-inner-icon="mdi-clock-outline"
                @change="changeTimeHandler"
              />
            </v-flex>

            <v-flex xs12>
              <v-text-field
                data-test="patient-edit-input-video-reason"
                id="motivo_video"
                v-model="nuevavideo.motivo"
                filled
                dense
                outlined
                :placeholder="$t('patient.motivo_consulta')"
                single-line
                full-width
                hide-details
              />
            </v-flex>
            <v-flex v-if="mostrarComoComunicar && nuevavideo.tipo.precio > 0" xs1 />
            <v-flex v-if="mostrarComoComunicar && nuevavideo.tipo.precio > 0" xs12>
              <v-card flat outlined class="my-4">
                <v-toolbar flat dense cad>
                  <v-toolbar-title class="subtitle-2">{{ $t('videoconsulta.tipo_cita') }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="py-0">
                  <v-radio-group v-model="nuevavideo.comunicacion" :mandatory="false">
                    <v-radio
                      v-if="
                        (nuevavideo.tipo === '' || nuevavideo.tipo.precio > 0) && nuevavideo.destinatario.length < 2
                      "
                      :label="$t('videoconsulta.solicitando_pago')"
                      color="primary"
                      value="1"
                    />
                    <v-radio
                      v-if="paymentSettings && !paymentSettings.isFreemium"
                      :label="$t('videoconsulta.cita_cortesia')"
                      color="primary"
                      value="2"
                    />
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex v-if="mostrarComoComunicar && nuevavideo.tipo.precio > 0" xs1 />
            <v-flex
              v-if="
                (nuevavideo.destinatario.length >= 1 && !ocultar_noti_sms) ||
                  (pacientes.length >= 1 &&
                    pacientes[0] &&
                    pacientes[0].telefono !== '' &&
                    pacientes[0].telefono !== null)
              "
              xs11
            >
              <span v-if="!haveCreateVideoWithTel">
                <v-checkbox
                  v-model="nuevavideo.noti_sms"
                  :label="$t('videoconsulta.convocatoria_sms', { sms: smsTrans })"
                  color="primary"
                  @change="forzarCheckNoty"
                />
              </span>
            </v-flex>
          </template>
        </v-layout>
      </v-card-text>
      <v-toolbar v-if="!es_fullscreen" flat>
        <v-spacer />
        <v-btn data-test="patient-edit-button-video-cancel" text color="grey darken-2" class="mr-2" dark @click="cancelHandler">{{ $t('common.cancel') }}</v-btn>
        <template v-if="!tiposSelect.length && !is_receptionist">
          <v-btn color="primary" text @click="goToConfig">{{ $t('menu.settings') }}</v-btn>
        </template>
        <template v-if="tiposSelect.length">
          <v-btn data-test="patient-edit-button-video-send" :loading="loadingCrear" depressed color="primary" dark @click="enviarVideo">{{ tipoVideoBtn }}</v-btn>
        </template>
      </v-toolbar>
    </div>

    <div v-else>
      <v-card>
        <v-card-title class="title lighten-1">{{ $t('videoconsulta.notice') }}</v-card-title>
        <v-card-text class="pa-4">
          {{ $t('videoconsulta.no_specialties_to_create') }}
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark text @click="cancelarNuevaVideo">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn color="primary" depressed class="white--text" @click="goToProfile">
              {{ $t('common.go_to_profile') }}
            </v-btn>
          </v-toolbar>
        </v-card-text>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import vSelect from 'vue-select';
import { mapState } from 'vuex';
import Alerts from '@/mixins/Alerts';
import { guardarVideoconsultaUrl, getHeader, defaultCountry } from '@/config/config';
import { isValidDate, isDateAndHourGreaterThanNow } from '@/utils';
import { accountNamePaths } from '@/router/routes';
import {getProfessional} from "@/views/professionals/professionals.api";

export default {
  name: 'Nuevavideo',
  components: {
    vSelect,
  },
  mixins: [Alerts],
  props: {
    pacientes: {
      type: Array,
      default: () => [],
    },
    tipos: {
      type: Array,
      default: () => [],
    },
    profesionales: {
      type: Array,
      default: () => [],
    },
    pack: {
      type: Object,
      default: () => [],
    },
    tipo: {
      type: String,
      default: () => '',
    },
    especialidades: {
      type: Array,
      default: () => [],
    },
    specialties: {
      type: Array,
      default: () => [],
    },
    /*
    paymentSettings: {
      type: Object,
      default: {}
    },
    */
    isFromVideoconsultas: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      tamAlto: window.innerWidth > 800 ? '' : 'min-height: ' + window.innerHeight + 'px',
      es_fullscreen: window.innerWidth <= 800,
      nuevavideo: {
        motivo: '',
        fecha: null,
        hora: null,
        destinatario: [],
        tipo: '',
        comunicacion: false,
        id: 0,
        noti_sms: false,
        profesional_id: 0,
        especialidad: '',
        profesional: '',
        specialtySelected: 0,
      },
      tele: false,
      mostrarComoComunicar: true,
      menu: false,
      modal2: false,
      forzar_check_noty: false,
      disabledButton: false,
      ocultar_noti_sms: true,
      timeout: 8000,
      mode: '',
      alerta: {
        texto: '',
        icono: 'mdi-message-text',
        color: 'info',
      },
      allTypes: [],
      tiposSelect: [],
      estadolabel: false,
      pack_id: 0,
      especialidad_id: 0,
      loadingCrear: false,
      tipoVideoTrans: this.$t('videoconsulta.singular'),
      tipoVideoBtn: this.$t('videoconsulta.crear_video'),
      smsTrans: 'sms',
      haveCreateVideoWithTel: false,
      forceShowInput: false,
      patientsPhone: [],
      patientPhone: '',
      patientName: '',
      showMsgDisabledMoreThanOnePatient: false,
      phone: '',
      phonePatient: {
        isValid: false,
      },
      bindProps: {
        mode: 'international',
        defaultCountry: 'ES',
        preferredCountries: ['ES', 'FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: 'Introduzca el número de teléfono del paciente',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      is_receptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      is_professional:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 2,
      deletedOriginPatient: false,
      countryCode: '+34',
      receiverInput: '',
      showSpecialtiesMsg: false,
      defaultCountryPhone: defaultCountry ? defaultCountry : 'es',
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      paymentSettings: state => state.app.paymentSettings,
    }),
    dateFormatted() {
      return this.nuevavideo && this.nuevavideo.fecha
        ? this.$moment(this.nuevavideo.fecha).format('DD MMMM YYYY')
        : null;
    },
    showType() {
      return this.is_receptionist ? this.nuevavideo.profesional : true;
    },
    showMinimumServices() {
      return this.is_receptionist ? this.nuevavideo.profesional && !this.tiposSelect.length : !this.tiposSelect.length;
    }
  },
  watch: {
    pack: function(p) {
      if (typeof p.id !== 'undefined' && p.id > 0) {
        this.mostrarComoComunicar = false;
        this.nuevavideo.motivo =
          p.nombrePack + ' ' + this.$t('packs.sesion') + ': ' + (p.sesiones_total - p.sesiones_pendientes + 1);
      }
    },
    pacientes: function(pac) {
      if (pac.length === 1) {
        this.bindProps.disabled = true;
        if (pac[0].notificacionSms === 1) {
          this.nuevavideo.noti_sms = true;
        }
        if (!this.forzar_check_noty) {
          this.ocultar_noti_sms = pac[0].telefono === '' || pac[0].telefono === null;
        }

        if (this.haveCreateVideoWithTel) {
          let phoneChanged = false;
          if (
            typeof pac[0] !== 'undefined' &&
            typeof pac[0].code_phone !== 'undefined' &&
            typeof pac[0].phone !== 'undefined'
          ) {
            this.patientPhone = pac[0].code_phone + pac[0].phone;
            this.patientsPhone.push({
              patient_id: pac[0].value,
              phone: this.patientPhone,
            });
            phoneChanged = true;
          }

          if (!phoneChanged && typeof pac[0].telefono !== 'undefined' && typeof pac[0].codTelefono !== 'undefined') {
            this.patientPhone = pac[0].codTelefono + pac[0].telefono;
            this.patientsPhone.push({
              patient_id: pac[0].value,
              phone: this.patientPhone,
            });
          } else if (!phoneChanged && typeof pac[0].telefono !== 'undefined') {
            // this.patientPhone = "+34" + pac[0].telefono;
            this.bindProps.defaultCountry = this.getCountryName();
          }
        }
      } else if (pac.length > 0) {
        this.bindProps.disabled = false;
      }
    },

    tipo(val) {
      this.tipoVideoTrans = val === 'tele' ? this.$t('videoconsulta.teleconsulta') : this.$t('videoconsulta.singular');
      this.tipoVideoBtn = val === 'tele' ? this.$t('videoconsulta.crear_tele') : this.$t('videoconsulta.crear_video');
    },

    tipos(val) {
      this.allTypes = val;
      this.tiposSelect = val;
      if (val.length === 1) {
        this.nuevavideo.tipo = val[0];
      } else {
        this.nuevavideo.tipo = '';
      }
    },

    profesionales(_val) {
      this.autoCompleteData();
    },

    specialties: {
      handler: function(val) {
        this.nuevavideo.specialtySelected = val.length === 1 ? val[0].id : 0;
      },
    },

    nuevavideo: {
      handler: function(val) {
        if (val.destinatario.length === 1) {
          this.pacientes.forEach(pac => {
            if (pac.value === val.destinatario[0]) {
              this.ocultar_noti_sms = !(pac.telefono !== '' && pac.telefono !== null);
            }
          });
        }
        this.smsTrans = this.$t('videoconsulta.sms');
        this.bindProps.disabled = val.destinatario.length > 0;
      },
      deep: true,
    },
  },
  created() {
    if (
      (this.is_receptionist || this.is_professional) &&
      typeof this.permissions.haveCreateVideoWithTel !== 'undefined'
    ) {
      this.haveCreateVideoWithTel = this.permissions.haveCreateVideoWithTel;
    }

    this.bindProps.placeholder = this.$t('user.telefono_paciente');
    this.setPhoneOnePatient();
  },
  mounted() {
    this.allTypes = this.tipos;
    this.tiposSelect = this.tipos;
    this.showSpecialtiesMsg = this.specialties.length === 0;
    if (this.tipos.length === 1) {
      this.nuevavideo.tipo = this.tipos[0];
    } else {
      this.nuevavideo.tipo = '';
    }
    this.autoCompleteData();
  },

  methods: {
    callEvent(number) {
      this.$dialog.call({
        number: number,
        name: this.patientName,
      });
    },

    autoCompleteData() {
      const autocomplete = JSON.parse(window.localStorage.getItem('videoconsultation_data'));
      if (autocomplete && autocomplete.fecha !== '') {
        this.nuevavideo.fecha = autocomplete.dia;
        this.nuevavideo.hora = autocomplete.hora;
        if (this.profesionales.length > 0) {
          if (autocomplete.profesional_id) {
            let pos = 0;
            let cont = 0;
            this.profesionales.forEach(function(elem) {
              if (elem.id === autocomplete.profesional_id) {
                pos = cont;
              }
              cont++;
            });

            this.nuevavideo.profesional = this.profesionales[pos];
          } else {
            this.nuevavideo.profesional = this.profesionales[0];
          }
          const types = [];
          this.allTypes.forEach(type => {
            if (type.profesional_id === this.nuevavideo.profesional.id) {
              types.push(type);
            }
          });
          this.tiposSelect = types;
        }
        localStorage.removeItem('videoconsultation_data');
      }
    },

    changeTimeHandler() {
      const step = 5;
      const fullTime = this.nuevavideo.hora.split(':');
      const exceedsMaxTime = Math.ceil(fullTime[1] / step) * step >= 60;
      const minuteTime = exceedsMaxTime ? 0 : Math.ceil(fullTime[1] / step) * step;
      const minute = minuteTime < 10 ? '0' + minuteTime : minuteTime;
      const hourTime = exceedsMaxTime && fullTime[0] < '23' ? parseInt(fullTime[0]) + 1 : fullTime[0];
      const hour = hourTime < 10 ? '0' + parseInt(hourTime) : hourTime;
      this.nuevavideo.hora = hour + ':' + minute;
    },

    quitarDestinatario(item) {
      this.patientsPhone = [];
      this.patientPhone = '';
      if (this.haveCreateVideoWithTel && item === '' && this.pacientes.length === 1) {
        this.pacientes.push(this.pacientes[0]);
        this.deletedOriginPatient = true;
        this.nuevavideo.destinatario = [];
      } else {
        const index = this.nuevavideo.destinatario.indexOf(item.value);
        if (index >= 0) {
          this.nuevavideo.destinatario.splice(index, 1);
        }
        if (this.nuevavideo.destinatario.length) {
          this.setPhone(this.nuevavideo.destinatario);
        }
      }
    },
    seleccionarDestinatario(item) {
      if (this.nuevavideo.destinatario.indexOf(item.value) > -1 && this.nuevavideo.destinatario.length < 2) {
        this.nuevavideo.destinatario.push(item.value);
      }
    },
    quitarTipo() {
      this.nuevavideo.tipo = '';
    },
    quitarProfesional() {
      this.nuevavideo.profesional = '';
      this.quitarTipo();
    },

    findProfessionalByName(name) {
      return this.profesionales.find((prof) => prof.label === name);
    },

    async cambiaProfesional(val) {
      const professionalSelected = this.findProfessionalByName(val);
      this.nuevavideo.profesional = professionalSelected;

      try {
        const {specialties} = await getProfessional(professionalSelected.id);
        this.specialties = specialties;
        this.specialtySelected = this.specialties[0].id;
      } catch (e) {

      }

      if (this.nuevavideo.profesional === '') {
        this.tiposSelect = this.allTypes;
      } else {
        const types = [];
        this.allTypes.forEach(type => {
          if (type.profesional_id === this.nuevavideo.profesional.id) {
            types.push(type);
          }
        });
        this.tiposSelect = types;
        if (types.length === 1) {
          this.nuevavideo.tipo = types[0];
        }
      }
    },

    cambiaTipo() {
      this.nuevavideo.tipo = this.tiposSelect.find((tipo) => tipo.id === this.nuevavideo.tipo);
      this.mostrarComoComunicar = this.nuevavideo.tipo.precio > 0;
    },

    cancelHandler() {
      if (this.is_receptionist && !this.tiposSelect.length) {
        this.quitarProfesional();
      } else {
        this.cancelarNuevaVideo();
      }
    },

    cancelarNuevaVideo() {
      const intercom = document.getElementsByClassName('intercom-launcher')[0];
      if (typeof intercom !== 'undefined' && intercom !== null) {
        intercom.style.display = 'block';
      }
      document.body.removeAttribute('class', 'noscroll');
      this.resetNuevaVideo();
      if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
        this.$router.go(-1);
      } else {
        this.$emit('clickedVolver', true);
      }
    },

    forzarCheckNoty() {
      this.forzar_check_noty = true;
    },

    clickSelectPatient() {
      const autocomplete = document.getElementsByClassName('v-autocomplete__content menuable__content__active');
      if (autocomplete && autocomplete[0]) {
        document.getElementsByClassName('v-autocomplete__content menuable__content__active')[0].style.display = 'block';
      }
    },

    selectPatient(sel_index_patient) {
      this.receiverInput = '';
      const disabledRestriction = true;
      if (
        !disabledRestriction &&
        (sel_index_patient.length > 3 ||
          (this.paymentSettings && this.paymentSettings.isFreemium && sel_index_patient.length > 1))
      ) {
        this.nuevavideo.destinatario.pop();

        const msg =
          sel_index_patient.length > 3 ? this.$t('errors.max_patients_multi') : this.$t('errors.freemium_no_multi');
        this.$toast.error(msg);
      } else {
        if (this.haveCreateVideoWithTel) {
          this.setPhone(sel_index_patient);
        }

        const autocomplete = document.getElementsByClassName('v-autocomplete__content menuable__content__active');
        if (autocomplete && autocomplete[0]) {
          document.getElementsByClassName('v-autocomplete__content menuable__content__active')[0].style.display =
            'none';
        }
      }
    },

    setPhone(sel_index_patient) {
      this.patientsPhone = [];
      this.patientPhone = '';
      sel_index_patient.forEach(p => {
        const index = this.pacientes.findIndex(function(pac) {
          return pac.value === p;
        });

        if (
          typeof this.pacientes[index] !== 'undefined' &&
          typeof this.pacientes[index].code_phone !== 'undefined' &&
          typeof this.pacientes[index].phone !== 'undefined'
        ) {
          const phone_with_code = this.pacientes[index].code_phone + this.pacientes[index].phone;
          this.patientsPhone.push({
            patient_id: this.pacientes[index].value,
            phone: phone_with_code,
          });
          this.patientPhone += this.patientPhone === '' ? phone_with_code : ', ' + phone_with_code;
        }
      });
    },

    resetNuevaVideo() {
      this.nuevavideo.comunicacion = false;
      this.nuevavideo.destinatario = [];
      this.nuevavideo.motivo = '';
      this.nuevavideo.specialtySelected = this.specialties.length === 1 ? this.specialties[0].id : 0;
      this.nuevavideo.fecha = null;
      this.nuevavideo.noti_sms = false;
      this.nuevavideo.hora = null;
      this.nuevavideo.tipo = this.tiposSelect.length === 1 ? this.tiposSelect[0] : '';
      this.deletedOriginPatient = false;
      this.patientPhone = '';
      this.patientName = '';
      this.patientsPhone = [];
      this.loadingCrear = false;
    },

    validateNewVideo() {
      let text_error = '';
      if (!this.haveCreateVideoWithTel && this.nuevavideo.destinatario.length === 0) {
        text_error = this.$t('videoconsulta.add_destinatario');
      } else if (this.nuevavideo.fecha === null || this.nuevavideo.hora === null) {
        text_error = this.$t('videoconsulta.add_fechahora');
      } else if (
        !this.haveCreateVideoWithTel &&
        this.nuevavideo.tipo.precio > 0 &&
        this.nuevavideo.comunicacion === false &&
        this.mostrarComoComunicar === true
      ) {
        text_error = this.$t('videoconsulta.add_modocomunicacion');
      } else if (this.nuevavideo.tipo === '') {
        text_error = this.$t('videoconsulta.add_tiposervicio');
      } else if (!isValidDate(this.nuevavideo.fecha)) {
        text_error = this.$t('errors.incorrect_date');
      } else if (!isDateAndHourGreaterThanNow(this.$moment(this.nuevavideo.fecha).format('MM/DD/YYYY'), this.nuevavideo.hora)) {
        text_error = this.$t('errors.date_must_be_greater_than_now');
      }

      const hourRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      const correctHour = hourRegex.exec(this.nuevavideo.hora);
      if (!correctHour) {
        text_error = this.$t('errors.incorrect_hour');
      }

      if (this.haveCreateVideoWithTel) {
        if (this.patientPhone !== '') {
          if (this.clearString(this.patientPhone).length === 9) {
            this.patientPhone = this.countryCode + this.patientPhone;
          }

          const match = this.validatePhone(this.patientPhone);
          if (!match && this.nuevavideo.destinatario.length === 1) {
            text_error = this.$t('errors.format_phone');
          } else {
            this.nuevavideo.phone = this.clearString(this.patientPhone);
            this.nuevavideo.patientsPhone = this.patientsPhone;
            this.nuevavideo.patientName = this.patientName;
            this.nuevavideo.noti_sms = true;
          }
        } else {
          text_error = this.$t('errors.phone_empty');
        }
      }

      if (!this.haveCreateVideoWithTel && this.is_receptionist && this.nuevavideo.profesional === '') {
        text_error = this.$t('errors.form_invalid');
      }

      if (
        this.specialties.length > 1 &&
        (this.nuevavideo.specialtySelected === 0 || typeof this.nuevavideo.specialtySelected === 'undefined')
      ) {
        text_error = this.$t('reserva.titulo_especialidad');
      }

      return text_error;
    },

    enviarVideo() {
      if (this.pacientes.length === 1) {
        this.nuevavideo.destinatario = [];
        this.nuevavideo.destinatario.push(this.pacientes[0].value);
      }
      this.nuevavideo.tipo = this.tiposSelect.length === 1 ? this.tiposSelect[0] : this.nuevavideo.tipo;
      this.nuevavideo.comunicacion =
        this.nuevavideo.tipo.precio === 0 && this.nuevavideo.comunicacion === '1'
          ? false
          : this.nuevavideo.comunicacion;
      this.alerta.texto = '';

      const msg = this.validateNewVideo();
      if (msg !== '') {
        this.$toast.error(msg);
      } else if (!this.disabledButton) {
        this.loadingCrear = true;
        this.dialog = false;
        this.disabledButton = true;
        if (this.nuevavideo.tipo.precio === 0) {
          this.nuevavideo.comunicacion = '2';
        }

        if (this.haveCreateVideoWithTel && this.deletedOriginPatient && this.pacientes.length < 2) {
          this.nuevavideo.destinatario = [];
        }
        this.$http
          .post(
            guardarVideoconsultaUrl,
            {
              user_id: this.authUser.id,
              videoconsulta: this.nuevavideo,
              pack: this.pack,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && (response.data.estado === '1' || response.data.estado === '3')) {
              this.resetNuevaVideo();
              if (this.haveCreateVideoWithTel && response.data.estado === '1') {
                this.$emit('reloadvideos');
                this.alerta.texto =
                  response.data.estado === '1'
                    ? this.$t('videoconsulta.enviado_correctamente_sms')
                    : this.$t('videoconsulta.enviado_recordatorio');
                this.$toast.success(this.alerta.texto);
              } else {
                this.$emit('clickedNuevo', response.data);
              }
            } else if (response.status === 200 && response.data.estado === '2') {
              this.$toast.error(this.$t('videoconsulta.solapamiento'));
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
            this.disabledButton = false;
            this.loadingCrear = false;
          })
          .catch(e => {
            let msg = e.data && e.data.error ? this.$t(e.data.error) : this.$t('errors.try_again');
            if (e.data.errors) {
              const errors = e.data.errors;
              if (typeof errors['videoconsulta.fecha'] !== 'undefined') {
                msg = errors['videoconsulta.fecha'][0];
              } else if (typeof errors['videoconsulta.hora'] !== 'undefined') {
                msg = errors['videoconsulta.hora'][0];
              }
            }
            this.$toast.error(msg);
            this.disabledButton = false;
            this.loadingCrear = false;
          });
      }
    },

    validatePhoneVueTel(phone) {
      this.phonePatient = phone;
    },

    changePhone(tel) {
      tel = this.clearString(tel);
      if (tel.length > 11) {
        const match = this.validatePhone(tel);
        if (match) {
          const patientSearch = this.pacientes.find(i => {
            return i.code_phone + i.phone === tel;
          });

          if (typeof patientSearch !== 'undefined' && typeof patientSearch.value !== 'undefined') {
            this.nuevavideo.destinatario[0] = patientSearch.value;
          }

          if (typeof patientSearch !== 'undefined') {
            this.patientName = patientSearch.label;
          }
        } else {
          this.patientName = '';
        }
      } else if (tel.length === 0) {
        this.patientName = '';
        this.patientPhone = '';
      }
    },

    validatePhone(phone) {
      phone = this.clearString(phone);
      let regex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
      if (phone.length === 9) {
        regex = /^[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      }

      return regex.exec(phone);
    },

    clearString(str) {
      return str.replace(/ +/g, '').replace(/-/g, '');
    },

    getCountryName() {
      fetch('https://extreme-ip-lookup.com/json/')
        .then(res => res.json())
        .then(response => {
          return response.countryCode;
        })
        .catch((_data, _status) => {
          console.log('Request failed');
        });
    },

    goToConfig() {
      this.$router.push('/ajustes/videoconsultas');
    },

    setPhoneOnePatient() {
      if (this.patientPhone === '' && this.pacientes.length === 1) {
        this.patientPhone = this.pacientes[0].code_phone + this.pacientes[0].phone;
      }
    },

    changeCountry(val) {
      this.countryCode = '+' + val.dialCode;
    },

    goToProfile() {
      this.$router.push({ name: accountNamePaths.profile });
    },
  },
};
</script>

<style lang="css">
.vue-tel-input {
  border: none !important;
  border-radius: unset !important;
  border-bottom: 1px solid black !important;
  margin-top: 10px;
  width: 98%;
}

.clear {
  display: none;
}

.hora_nueva_video {
  width: 92%;
}
.listado_motivos_cortesia {
  margin-left: 30px;
}

.buscadorSelector .dropdown-toggle .clear {
  color: #fff;
  font-size: 1px;
}

.contenido_nueva_video {
  overflow-x: initial;
}

.noscroll {
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
}

.subheader {
  font-size: 13px;
}

@media only screen and (max-width: 500px) {
  .contenido_nueva_video {
    /*max-height: 520px;*/
    overflow-x: scroll;
  }
  .titulo_nueva_video {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 320px) {
  .contenido_nueva_video {
    /*max-height: 377px;*/
    overflow-x: scroll;
  }
  .titulo_nueva_video {
    font-size: 14px !important;
  }
}

.alerta_notipos {
  margin: 10px 0;
  padding: 7px;
  width: 97%;
  cursor: pointer;
  color: #f57f17;
  border: 1px solid #f57f17;
}
</style>
